.main {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 auto;
  width: 384px;
}

.guessComp {
  width: 100%;
  margin: 0 5px;
}

.guessInput {
  width: 100%;
  height: 40px;
}

.guessForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.guessFormInner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 70px;
  border: 3px solid grey;
  margin: 6px 0;
}

.enterBtn {
  display: none;
}

.shareArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.toggleBtn {
  height: 50%;
  line-height: 0;
  border-radius: 0;
  border: none;
  cursor: pointer;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 7px;
  width: 100%;
}

.black {
  background-color: white;
  color: black;
}

.white {
  background-color: black;
  color: white;
}

.modalBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  outline: 0;
  overflow: scroll;
}

.css-ahj2mt-MuiTypography-root.modalTypog {
  line-height: 1.5rem;
  margin: 1rem 0;
}

.modalList {
  list-style: none;
}

.modalExamples {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.MuiTypography-h1.logoTypog {
  font-weight: 400;
  font-size: 40px;
  color: #9c9c9c;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

@media only screen and (max-width: 780px) {
  .enterBtn {
    display: block;
  }
}

@media only screen and (max-width: 400px) {
  .main {
    width: 100%;
    margin: 0 2px;
  }
  .css-4fmfnx-MuiInputBase-root-MuiInput-root.guessInput {
    font-size: 100% !important;
  }
  .guessComp {
    font-size: 75%;
  }
}
